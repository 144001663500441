import Table from "../../components/Table";

import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../context/AuthContext";
import api from "../../services/api";

import PrimaryButton from "../../components/Button/PrimaryButton";
import LoadingTable from "../../components/LoadingTable";
import InfoContext from "../../context/InfoContext";
import { SolicitationsProps } from "../../types/Solicitations";

export default function FactorySolicitationForm() {
    const [solicitationList, setSolicitationList] = useState<
        SolicitationsProps[]
    >([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { handleLogOut, user } = useContext(Context);
    const { reloadData, setReloadData } = useContext(InfoContext);

    let { id }: { id: string } = useParams();

    useEffect(() => {
        async function getUsers() {
            setSolicitationList([]);
            try {
                setIsLoading(false);
                const { data } = await api.get("solicitations");

                setSolicitationList(data.list);
                setIsLoading(true);
            } catch {
                handleLogOut();
            }
        }

        getUsers();
    }, [id, reloadData]);

    return (
        <div>
            <div className="flex justify-between items-center">
                <h1 className="font-bold text-2xl">Solicitações</h1>
                <Link to="/solicitacao-formulario">
                    <PrimaryButton title="Solicitar" />
                </Link>
            </div>

            {
                isLoading ?
                    <>
                        {solicitationList.length ? (
                            <Table list={solicitationList} />
                        ) : (
                            // <LoadingTable />
                            <div className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">

                                <div className="py-16">
                                    <div className="text-center">
                                        <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide"></p>
                                        <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">No momento não temos solicitações agendadas</h1>
                                        <p className="mt-2 text-base text-gray-500"></p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                    :
                    <LoadingTable />
            }


        </div>
    );
}
