import Table from "../../components/Table";

import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../context/AuthContext";
import api from "../../services/api";

import PrimaryButton from "../../components/Button/PrimaryButton";
import LoadingTable from "../../components/LoadingTable";
import InfoContext from "../../context/InfoContext";
import { SolicitationsProps } from "../../types/Solicitations";

interface IAuditoria {
    is_correct_address: boolean;
    is_request_ready: boolean;
    is_there_someone: boolean;
    name_person: string;
}

interface ISolicitation {
    id: string;
    address: {
        zip_code: string;
        address: string;
        number: string;
        district: string;
        description: string;
        city: string;
        state: string;
    };
}

export default function PreAuditoriaAnswer() {

    const [auditoria, setAuditoria] = useState<IAuditoria>();
    const [solicitacao, setSolicitacao] = useState<ISolicitation>();

    const { handleLogOut, user } = useContext(Context);
    const { reloadData, setReloadData } = useContext(InfoContext);

    let { id }: { id: string } = useParams();

    useEffect(() => {
        async function getAudits() {

            try {

                const { data } = await api.get(`audit/solicitation/${id}`);
                const responseSolitacao = await api.get(`solicitations/${id}`);

                console.log(responseSolitacao.data)
                console.log(data);
                setAuditoria(data);
                setSolicitacao(responseSolitacao.data);
            } catch {
                handleLogOut();
            }
        }

        getAudits();
    }, [id, reloadData]);

    return (
        <div>
            <div className="flex justify-between items-center">
                <h1 className="font-bold text-2xl">Resultado da pré auditoria</h1>
                <Link to="/">
                    <PrimaryButton title="Voltar" />
                </Link>
            </div>

            <br />

            <div className="bg-white">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
                    <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                        <div>
                            <h2 className="text-3xl font-extrabold text-gray-900">Pré auditoria</h2>
                            <p className="mt-4 text-lg text-gray-500">
                                Resultado da pré auditoria

                            </p>
                        </div>
                        <div className="mt-12 lg:mt-0 lg:col-span-2">
                            <dl className="space-y-12">

                                <div >
                                    <dt className="text-lg leading-6 font-large text-gray-900">
                                        O endereço {solicitacao && solicitacao.address.zip_code}  {solicitacao && solicitacao.address.address}
                                        nº {solicitacao && solicitacao.address.number},
                                        Cidade: {solicitacao && solicitacao.address.city}
                                        e Estado: {solicitacao && solicitacao.address.state} realmentes existe?
                                    </dt>
                                    <dd className="mt-2 text-base text-gray-500">
                                        {auditoria?.is_correct_address ? 'Sim' : 'Não'}
                                    </dd>
                                </div>

                                <div >
                                    <dt className="text-lg leading-6 font-large text-gray-900">
                                        O Pedido está pronto para a inspeção?
                                    </dt>
                                    <dd className="mt-2 text-base text-gray-500">
                                        {auditoria?.is_request_ready ? 'Sim' : 'Não'}
                                    </dd>
                                </div>

                                <div >
                                    <dt className="text-lg leading-6 font-large text-gray-900">
                                        Tem alguém para acompanhar?
                                    </dt>
                                    <dd className="mt-2 text-base text-gray-500">
                                        {auditoria?.is_there_someone ? 'Sim' : 'Não'}
                                    </dd>
                                </div>

                                {
                                    auditoria?.is_there_someone ?
                                        <>
                                            <div >
                                                <dt className="text-lg leading-6 font-large text-gray-900">
                                                    Nome da Pessoa que acompanhou?
                                                </dt>
                                                <dd className="mt-2 text-base text-gray-500">
                                                    {auditoria?.name_person}
                                                </dd>
                                            </div>
                                        </>
                                        :
                                        <>
                                        </>
                                }

                            </dl>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}
